export default {
  id: 'compiled',
  // type: 'http-get',
  // dependent: 'none',
  resettable: false,
  // url: 'https://services.arcgis.com/fLeGjb7u4uXqeF9q/arcgis/rest/services/Senior_Sites_PUBLIC_VIEW/FeatureServer/0/query',
  // options: {
  //   params: {
  //     where: '1=1',
  //     outFields: '*',
  //     f: 'pjson',
  //   },
  // },
};
